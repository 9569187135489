import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import Customers from '../../../../components/airtable';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Crypto from 'crypto-js';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

const Dashboard = () => {
  const theme = useTheme();
  const [customers, updateCustomers] = useState([]);
  const [credentials, setCredentials] = useState(null);
  const [showCredentials, setShowCredentials] = useState(false);
  const [showSendAgreement, setShowSendAgreement] = useState(false);
  const [subjectLine, setSubjectLine] = useState('');
  const [messageBody, setMessageBody] = useState('');
  const [sendAgreementItem, setSendAgreementItem] = useState(null);

  useEffect(() => {
    if (window) {
      if (customers.length > 0) {
        updateCustomers([]);
      }
      Customers('Questionaire').select({
        view: "InProcessFiles"
      }).eachPage((records, fetchNextPage) => {

        updateCustomers([...customers, ...records]);

        fetchNextPage();
      }, function done(err) {
        if (err) {
          console.log(err);
        }

      });

    }

  }, []);

  function showPayrollCredentials(raw) {
    var bytes = Crypto.AES.decrypt(raw, 'Ragaasavari2o21!!!');
    var creds = JSON.parse(bytes.toString(Crypto.enc.Utf8));

    setCredentials(creds);
    setShowCredentials(true);
  }

  function handleCredentialDialogClose() {
    setShowCredentials(false);
  }

  function handleSendAgreementDialogClose() {
    setShowSendAgreement(false);
  }

  function handleChange(event) {
    switch (event.target.id) {
      case 'subject-line':
        setSubjectLine(event.target.value);
        break;
      case 'message-body':
        setMessageBody(event.target.value);
        break;
      default:
        break;
    }
  }

  async function sendAgreement() {
    const message = {
      message: messageBody,
      subject: subjectLine,
      silent: false
    }

    let response = null;

    try {
      response = await fetch(
        `https://api.pandadoc.com/public/v1/documents/${sendAgreementItem.get('Agreement ID')}/send`,
        {
          method: 'POST',
          cache: 'no-cache',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'API-Key 3b8a14959d691938d016bf80a0550635dffc8ec8'
          },
          body: JSON.stringify(message)
        });
    }
    catch (e) {
      console.log(e);
    }
    finally {
      console.log(sendAgreementItem);
      if (response.status === 200 || response.status === 403) {
        const data = await response.json();
       // if (data.status === 'document.sent') {
          const update = await Customers('Questionaire').update([{
            "id": sendAgreementItem.id,
            "fields": {
              "Agreement Status": "Sent"
            }
          }]);
  
          console.log(update);
          window.location.reload();
        //}
        console.log(data);
      }
    }
    

  }

  function setDefaultEmailMessage(customer) {
    setSubjectLine(`ERT.credit Engagement Letter Prepared For ${customer.get('Business Name')}`)
    setMessageBody(`Hello ${customer.get('First Name')}, 

    Here is the agreement which covers the work required to file for your Employee Retention Tax Credit. 
          
    Please NOTE: No fees are due until the work is completed and your credit exceeds the fees for this service. 
          
    Please feel free to call me at 571-220-6208, if you have any questions.

    Thank you.
          
    --Kash`)
  }

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 700,
          }}
          gutterBottom
          color={'text.secondary'}
          align={'center'}
        >
          Admin Dashboard Customers In Progress - {customers.length}
        </Typography>
        <Typography
          variant="h4"
          align={'center'}
          gutterBottom
          sx={{
            fontWeight: 700,
            marginTop: theme.spacing(1),
          }}
        >
          Open ERTC Client Files
        </Typography>
        <Typography variant="h6" align={'center'} color={'text.secondary'}>
          The cards below contain the open clients for which we have not yet completed the ERTC
          package.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {customers.length > 0 && customers.map((item, i) => (
          <Box marginLeft={1} marginRight={1} marginBottom={1} key={item.id}>
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography variant="h3" color="text.secondary" gutterBottom>
                  {item.get("Business Name")}
                </Typography>
                <Typography variant="h6" component="div">
                  {`${item.get('First Name')} ${item.get('Last Name')}`}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {`${item.get('Address Line 1')}, ${item.get('Abbreviation (from States)')[i]} ${item.get("Zip")}`}
                </Typography>

                <Chip
                  label={item.get('Phone')}
                  color="success"
                />
                <Box margin={1}>
                  <Chip
                    label={`Agreement ${item.get("Agreement Status")}`}
                    color="info"
                    variant="contained"
                    size="small" />
                </Box>
              </CardContent>
              <CardActions>
                <Button variant="contained"
                  onClick={() => showPayrollCredentials(item.get("Payroll System Credentials"))}>
                  Payroll Credentials
                </Button>
                {(item.get('Agreement Status') === 'In Process' || item.get('Agreement Status') === 'Resend') &&
                  item.get('Agreement ID') !== null &&
                  <Button variant="contained"
                    onClick={() => {
                      setSendAgreementItem(item);
                      setDefaultEmailMessage(item);
                      setShowSendAgreement(true);
                    }}
                    color="error">{item.get('Agreement Status') + ' Agreement'}
                  </Button>}
              </CardActions>
            </Card>
          </Box>
        ))}

      </Grid>
      {credentials &&
        <Dialog
          open={showCredentials}
          onClose={handleCredentialDialogClose}>
          <DialogTitle id="alert-dialog-title">
            Credentials
          </DialogTitle>
          <Box sx={{ p: 2, margin: '10px' }}>
            <Typography variant="subtitle1">
              Payroll System: {credentials.payrollSystem}
            </Typography>
            <Typography variant="subtitle1">
              Username: {credentials.login}
            </Typography>
            <Typography variant="subtitle1">
              Password: {credentials.password}
            </Typography>
            <Typography variant="subtitle1">
              Email: {credentials.email}
            </Typography>
            <Typography variant="subtitle1">
              Mobile: {credentials.mobile}
            </Typography>
          </Box>
        </Dialog>}
      {sendAgreementItem && <Dialog open={showSendAgreement}
        onClose={handleSendAgreementDialogClose}>
        <DialogTitle id="alert-dialog-title">
          Send Agreement Letter To Client - {sendAgreementItem.get("Business Name")}
        </DialogTitle>
        <Box component="form"
          sx={{
            '& .MuiTextField-root': {
              m: 1, width: '80%', marginLeft: '5ch',
              marginRight: '5ch'
            },
          }}>
          <div>
            <TextField
              error={subjectLine.trim() === '' ? true : false}
              onChange={handleChange}
              id="subject-line"
              label="Subject"
              value={subjectLine}
              helperText={subjectLine.trim() === '' ?
                'Subject line is required.' : ''}
              fullWidth={true}
            />
          </div>
          <div>
            <TextField
              error={messageBody.trim() === '' ? true : false}
              id="message-body"
              label="Message"
              onChange={handleChange}
              value={messageBody}
              helperText={messageBody.trim() === '' ?
                'Message is required.' : ''}
              rows={5}
              multiline
              fullWidth={true}
            />
          </div>
        </Box>
        <Box margin={2}>
          <Button style={{ margin: "10px" }} variant="contained"
            color="success" onClick={sendAgreement}>Send Agreement</Button>
          <Button color="error"
            variant="contained" onClick={handleSendAgreementDialogClose}>Cancel</Button>
        </Box>

      </Dialog>}
    </Box>

  );
};

export default Dashboard;
