import React from 'react';
import Admin from 'views/Admin';


const AdminPage = () => {

    return (
      <Admin />
    )
      
};

export default AdminPage;