import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { firebase } from '../../components/firebase';
import {
  Dashboard
} from './components';
import { Typography } from '@mui/material';

const admins = ['kash@badami.net', 'kash@ert.credit', 'jordan@anderncompany.com'];
const Admin = () => {
  const [showDashboard, setShowDashboard] = useState(false);

  useEffect(() => {
    if (window) {
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          if (admins.includes(user.email)) {
            setShowDashboard(true);
          }
        }
        else {
          setShowDashboard(false);
        }
      });
    }

  }, [])
  return (
    <Main>
      {showDashboard &&
        <>
          <Container>
            <Dashboard />
          </Container>
        </>
      }
      {
        !showDashboard &&
        <Container>
          <Typography variant="h4">
            Please login to view the admin dashboard.
          </Typography>
        </Container>
      }
    </Main>

  );
};

export default Admin;
